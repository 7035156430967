import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Form, Card, CardImg, CardBody} from "react-bootstrap";
import { Typography, TextField, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from "@mui/material";
import { Input, Label } from "reactstrap";
import swal from "sweetalert";
import axios from "../../helpers/axios";
import useSector from "../../hooks/useSector";
import useGoal from "../../hooks/useGoal";
import useRegion from "../../hooks/useRegion";
import useInnovationStatus from "../../hooks/useInnovtionStatus";
import { useNavigate, useParams } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const EditInnovationForm = ({ innovation, open, onClose, onUpdate }) => {
  const { id } = useParams();
  const [imageUrls, setImageUrls] = useState([]);
  const [featuredImgUrl, setFeaturedImgUrl] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const userId = localStorage.getItem('userId');
  const [completedStep1, setCompletedStep1] = useState(false);
  const navigate = useNavigate();
  const [listOfSectors] = useSector();
  const [listOfGoals] = useGoal();
  const [listOfRegions] = useRegion();
  const [listOfInnovationStatuses] = useInnovationStatus();
  const [userRole, setUserRole] = useState('');
  const [isOwner, setIsOwner] = useState(false); // State to track if user is the owner
  const [loading, setLoading] = useState(false);

  const [inputs, setInputs] = useState({
    userId: userId,
    regionId: "",
    innovationName: '',
    description: "",
    problemStatement: "",
    solution: "",
    tractionAndImpact: "",
    planForExpansion: "",
    sectorId: "",
    goalId: "",
    innovationStatusId: "",
    featuredImg: "",
    images: [],
    firstName: "", // Added firstName field
    lastName: "", // Added lastName field
  });

  useEffect(() => {
    fetchUserRole();
    fetchInnovationDetails();
  }, []);

  const fetchUserRole = async () => {
    try {
      const userId = localStorage.getItem('userId');
      const response = await axios.get(`/users/role/${userId}`);
      setUserRole(response.data.role);
    } catch (error) {
      console.error('Error fetching user role:', error);
    }
  };

  const fetchInnovationDetails = async () => {
    try {
      const response = await axios.get(`/innovations/${id}`);
      const innovation = response.data;
     setInputs((prevInputs) => ({
  ...prevInputs,
  userId: innovation.userId || "",
  regionId: innovation.regionId || "",
  innovationName: innovation.innovationName || "",
  description: innovation.description || "",
  problemStatement: innovation.problemStatement || "",
  solution: innovation.solution || "",
  tractionAndImpact: innovation.tractionAndImpact || "",
  planForExpansion: innovation.planForExpansion || "",
  sectorId: innovation.sectorId || "",
  goalId: innovation.goalId || "",
  innovationStatusId: innovation.innovationStatusId || "",
  featuredImg: innovation.featuredImg || "",
  firstName: innovation.firstName || "",
  lastName: innovation.lastName || "",
}));

      setImageUrls(innovation.images);
      setFeaturedImgUrl(innovation.featuredImg);
      setIsOwner(innovation.firstName || innovation.lastName);
    } catch (error) {
      console.error('Error fetching innovation details:', error);
    }
  };

  const isUserInnovator = userRole === 'innovator';

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });

    if (
      inputs.innovationName &&
      inputs.innovationStatusId &&
      inputs.sectorId &&
      inputs.goalId &&
      inputs.regionId &&
      inputs.description &&
      inputs.problemStatement &&
      inputs.solution &&
      inputs.tractionAndImpact &&
      inputs.planForExpansion 
    ) {
      setCompletedStep1(true);
    } else {
      setCompletedStep1(false);
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files).slice(0, 3);
    setSelectedImages(files);

    const imageUrlsArray = [];
    files.forEach(file => {
      const reader = new FileReader();
      reader.onload = () => {
        imageUrlsArray.push(reader.result);
        setImageUrls([...imageUrlsArray]);

        if (featuredImgUrl === "") {
          setFeaturedImgUrl(reader.result);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleSelectFeaturedImage = (imageUrl) => {
    setFeaturedImgUrl(imageUrl);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (currentStep === 1) {
      if (!inputs.innovationName || !inputs.innovationStatusId || !inputs.sectorId || !inputs.goalId || !inputs.regionId || !inputs.description || !inputs.problemStatement || !inputs.solution || !inputs.tractionAndImpact || !inputs.planForExpansion) {
        await swal('Validation Error', 'All innovation details fields are required.', 'error');
        return;
      }
      setCompletedStep1(true);
      nextStep();
    } else if (currentStep === 2) {
      const formData = new FormData();
      selectedImages.forEach(image => {
        formData.append('images', image);
      });
      formData.append('userId', inputs.userId);
      formData.append('regionId', inputs.regionId);
      formData.append('innovationName', inputs.innovationName);
      formData.append('description', inputs.description);
      formData.append('problemStatement', inputs.problemStatement);
      formData.append('solution', inputs.solution);
      formData.append('tractionAndImpact', inputs.tractionAndImpact);
      formData.append('planForExpansion', inputs.planForExpansion);
      formData.append('sectorId', inputs.sectorId);
      formData.append('goalId', inputs.goalId);
      formData.append('innovationStatusId', inputs.innovationStatusId);
      formData.append('featuredImg', featuredImgUrl);
      formData.append('firstName', inputs.firstName);
      formData.append('lastName', inputs.lastName);
  
      try {
        const response = await axios.put(`/innovations/${id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
  
        if (response.status === 200) {
          navigate('/');
          swal('Success', 'Innovation updated successfully!', 'success');
        } else {
          swal('Update Error', 'There was an issue updating your innovation. Please try again.', 'error');
        }
      } catch (error) {
        console.error('Error:', error);
        swal('Update Error', 'There was an issue updating your innovation. Please try again.', 'error');
      }
    }
  };

  const handleImageUrlChange = (e) => {
    const url = e.target.value;
    setImageUrls([...imageUrls, url]);
  };

  const removeImage = (index) => {
    const newImageUrls = imageUrls.filter((_, i) => i !== index);
    const newSelectedImages = selectedImages.filter((_, i) => i !== index);
    setImageUrls(newImageUrls);
    setSelectedImages(newSelectedImages);

    if (featuredImgUrl === imageUrls[index]) {
      setFeaturedImgUrl(newImageUrls.length > 0 ? newImageUrls[0] : "");
    }
  };

  const handleOwnershipChange = (e) => {
    const value = e.target.value === 'no';
    setIsOwner(value);

    if (!value) {
      setInputs({
        ...inputs,
        firstName: '',
        lastName: '',
      });
    }
  };

  return (
<Dialog
  open={open}
  onClose={onClose}
  maxWidth="md" // Change "md" to "lg" or "xl" for larger sizes
  fullWidth
>
  <DialogTitle>Edit Innovation</DialogTitle>
  <DialogContent>
    {currentStep === 1 && (
      <Form onSubmit={handleSubmit}>
        <Typography
          variant="h6"
          style={{
            backgroundColor: '#0468B1',
            color: 'white',
            padding: '8px',
          }}
        >
          EDIT INNOVATION DETAILS
        </Typography>
        <br />
        <div className="col-sm-4 d-flex align-items-center">
          <Label className="mr-3">Are you the owner of innovation? *</Label>
          <div className="form-check form-check-inline">
            <Input
              type="radio"
              id="yes"
              name="ownership"
              value="yes"
              checked={isOwner === false}
              onChange={handleOwnershipChange}
              className="form-check-input"
            />
            <Label htmlFor="yes" className="form-check-label mr-3">Yes</Label>
          </div>
          <div className="form-check form-check-inline">
            <Input
              type="radio"
              id="no"
              name="ownership"
              value="no"
              checked={isOwner === true}
              onChange={handleOwnershipChange}
              className="form-check-input"
            />
            <Label htmlFor="no" className="form-check-label">No</Label>
          </div>
        </div>
        {isOwner && (
          <>
                       <div className="form-group row">
              <div className="col-sm-4">
              <Label htmlFor="firstName">First Name *</Label>
              <Input
                type="text"
                id="firstName"
                name="firstName"
                value={inputs.firstName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-sm-4">
              <Label htmlFor="lastName">Last Name *</Label>
              <Input
                type="text"
                id="lastName"
                name="lastName"
                value={inputs.lastName}
                onChange={handleChange}
                required
              />
            </div>
            </div>
          </>
        )}
         <div className="form-group row">
        <div className="col-sm-4">
          <Label htmlFor="innovationName">Innovation Name</Label>
          <Input
            type="text"
            id="innovationName"
            name="innovationName"
            value={inputs.innovationName}
            onChange={handleChange}
          />
        </div>
        <div className="col-sm-4">
          <Label htmlFor="innovationStatusId">Status</Label>
          <Input
            type="select"
            id="status"
            name="innovationStatusId"
            value={inputs.innovationStatusId}
            onChange={handleChange}
          >
            <option value="">Select Innovation Stage</option>
            {listOfInnovationStatuses.map((s) => (
             <option value={s._id} key={s._id}> {s.name} </option>
            ))}
          </Input>
        </div>
        <div className="col-sm-4">
          <Label htmlFor="sectorId">Sector</Label>
          <Input
            type="select"
            id="sector"
            name="sectorId"
            value={inputs.sectorId}
            onChange={handleChange}
          >
            <option value="">Select Sector</option>
            {listOfSectors.map((sec) => (
             <option value={sec._id} key={sec._id}>
             {sec.name}
           </option>
            ))}
          </Input>
        </div>
        </div>
        <div className="form-group col-md-6">
          <Label htmlFor="regionId">Region</Label>
          <Input
            type="select"
            id="region"
            name="regionId"
            value={inputs.regionId}
            onChange={handleChange}
          >
            <option value="">Select Region</option>
            {listOfRegions.map((region) => (
               <option value={region._id} key={region._id}>
               {region.name}
             </option>
            ))}
          </Input>
        </div>
        <div className="form-group col-md-6">
          <Label htmlFor="regionId">SDG Goal</Label>
          <Input
            type="select"
            id="goal"
            name="goalId"
            value={inputs.goalId}
            onChange={handleChange}
          >
            <option value="">Select Goal</option>
            {listOfGoals.map((goal) => (
               <option value={goal._id} key={goal._id}>
               {goal.name}
             </option>
            ))}
          </Input>
        </div>
        <div className="row form-group">
              <div className="col-sm-12">
          <Label htmlFor="description">Description</Label>
          <Input
            type="textarea"
            id="description"
            name="description"
            value={inputs.description}
            onChange={handleChange}
          />
        </div>
        </div>
        <div className="row form-group">
              <div className="col-sm-12">
          <Label htmlFor="problemStatement">Problem Statement</Label>
          <Input
            type="textarea"
            id="problemStatement"
            name="problemStatement"
            value={inputs.problemStatement}
            onChange={handleChange}
          />
        </div>
        </div>
        <div className="row form-group">
              <div className="col-sm-12">
          <Label htmlFor="solution">Solution</Label>
          <Input
            type="textarea"
            id="solution"
            name="solution"
            value={inputs.solution}
            onChange={handleChange}
          />
        </div>
        </div>
        <div className="row form-group">
              <div className="col-sm-12">
          <Label htmlFor="tractionAndImpact">Traction and Impact</Label>
          <Input
            type="textarea"
            id="tractionAndImpact"
            name="tractionAndImpact"
            value={inputs.tractionAndImpact}
            onChange={handleChange}
          />
        </div>
        </div>
        <div className="row form-group">
              <div className="col-sm-12">
          <Label htmlFor="planForExpansion">Plan for Expansion</Label>
          <Input
            type="textarea"
            id="planForExpansion"
            name="planForExpansion"
            value={inputs.planForExpansion}
            onChange={handleChange}
          />
        </div>
        </div>
        
        <div className="form-group col-md-6">
          <Button type="button" variant="contained" style={{ marginBottom: '20px', backgroundColor: '#0468B1', color: 'white' }}  onClick={nextStep} disabled={!completedStep1}>
            Next
          </Button>
        </div>
      </Form>
    )}

    {currentStep === 2 && (
      <Form onSubmit={handleSubmit}>
        <Typography
          variant="h6"
          style={{
            backgroundColor: '#0468B1',
            color: 'white',
            padding: '8px',
          }}
        >
          EDIT INNOVATION IMAGES
        </Typography>
        <br />
        <div className="form-group col-md-6">
          <Label htmlFor="images">Upload Images (max 3)</Label>
          <Input
            type="file"
            id="images"
            name="images"
            accept="image/*"
            multiple
            onChange={handleImageChange}
          />
        </div>
        <div className="form-group col-md-6">
          <Label htmlFor="imageUrl">Or add image URLs</Label>
          <Input
            type="url"
            id="imageUrl"
            name="imageUrl"
            onBlur={handleImageUrlChange}
          />
        </div>
        <div className="d-flex flex-wrap">
          {imageUrls.map((url, index) => (
            <Card key={index} style={{ margin: '10px', maxWidth: '250px' }}>
              <CardImg src={url} alt={`Innovation Image ${index + 1}`} />
              <CardBody>
                <Button variant="contained"  onClick={() => handleSelectFeaturedImage(url)}>
                  {featuredImgUrl === url ? 'Selected as Featured' : 'Select as Featured'}
                  
                </Button>
                <IconButton onClick={() => removeImage(index)} >
                  <DeleteIcon />
                </IconButton>
              </CardBody>
            </Card>
          ))}
        </div>
        <div className="form-group row">
              <div className="col-sm-2">
        <Button type="button" onClick={prevStep} variant="contained" style={{ marginBottom: '20px', backgroundColor: '#0468B1', color: 'white' }}>Previous</Button>
           </div>
           <div className="col-sm-4">
          <Button type="submit" variant="contained"  style={{ marginBottom: '20px', backgroundColor: '#0468B1', color: 'white' }}  disabled={loading}>
            Save
          </Button>
          {loading && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12 }} />}
        </div>
        </div>
      </Form>
    )}
  </DialogContent>
</Dialog>

  );
};

export default EditInnovationForm;
