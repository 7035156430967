import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Form } from "reactstrap";
import { useDispatch } from "react-redux";
import axios from "../../helpers/axios";
import { authConstants } from "../../actions/constants";
import { Box, styled } from "@mui/system";
import { Card, Button, Stack, TextField } from '@mui/material'
import swal from 'sweetalert';

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: 'center',
}));

styled('img')(() => ({
  width: '100%',
}));

const JWTRegister = styled(JustifyBox)(() => ({
  backgroundImage: `url('path_to_your_background_image.jpg')`, // Replace with your background image path
  backgroundSize: 'cover',
  minHeight: '100vh !important',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .card': {
    maxWidth: 500,
    borderRadius: 12,
    background: 'rgba(255, 255, 255, 0.8)', // Semi-transparent white background for card
 
    textAlign: 'center',
  },
}));

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await axios.post(`/auth/login`, {
        email,
        password,
      });
      const { token, userId } = res.data;
  
      localStorage.setItem('token', token);
      localStorage.setItem('userId', userId);
      dispatch({
        type: authConstants.LOGIN_SUCCESS,
        payload: {
          token,
          userId,
        },
      });
      navigate('/');
    } catch (error) {
      console.error('Login failed:', error);
      const errorMessage = error.response?.data?.error || 'There was an error during login';
      await swal('Login Failed', errorMessage, 'error');
      
      // Reload the page after showing the error
      setTimeout(() => {
        window.location.reload();
      }, 2000); // Adjust delay if needed
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <JWTRegister>
      <Card className="card text-center">
       
       
          <Box p={4} height="100%">
          <div className="d-flex align-items-center justify-content-center mb-4">
    <Box component="img" src="https://digitalx.undp.org/images/630478c456d6a9a3e003e9f2_logo-blue.svg" sx={{ width: 74, height: 130 }} />
  </div>
  <h4 style={{ marginBottom: '20px', marginTop:'20px' }}>Sign In</h4>
            <Form onSubmit={userLogin}>
            <Stack spacing={4}>
             
               <TextField
                  className='form-control'
                  placeholder='Email'
                  value={email}
                  type="email"
                  style={{ marginBottom: '20px' }}
                  onChange={(e) => setEmail(e.target.value)}/>
             

             <TextField
                  className='form-control'
                  placeholder='Password'
                  value={password}
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}/>
             
              </Stack>
              <br/>
              

              <div className="form-group" style={{ marginBottom: '20px', marginTop:'20px' }}>
              <Button
                  
                  type="submit"
                  sx={{ textTransform: 'capitalize' }}
                  disabled={isLoading}
                  
                  >
                    {isLoading ? 'Please wait...' : 'Login'}
                </Button> | 
                <Button
                                        sx={{ textTransform: 'capitalize' }}
                                        onClick={() => navigate("/register/")}
                                    >Sign Up  </Button>

              </div>

              
              <div className="my-1 text-left">
              <Link to="/forgotpassword">Forgot Password?</Link>

              </div>
            </Form>
          </Box>
       
      </Card>
    </JWTRegister>
  );
};

export default Login;
