import {
  Grid,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tabs,
  Tab,
  IconButton,
  Tooltip,
} from '@mui/material';
import Layout from './layout';
import { Link } from 'react-router-dom';
import useUserInnovation from '../../hooks/userInnovations';
import AddInnovation from '../../features/innovations/AddInnovation';
import EditInnovationForm from '../../features/innovations/EditInnovation'; 
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import HistoryIcon from '@mui/icons-material/History'; // Recall icon
import axios from "../../helpers/axios";
import swal from "sweetalert";

const MyInnovationsPage = () => {
  const [listOfInnovations] = useUserInnovation();
  const [openModal, setOpenModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedInnovation, setSelectedInnovation] = useState(null);
  const [openRecallDialog, setOpenRecallDialog] = useState(false);
const [innovationToRecall, setInnovationToRecall] = useState(null);
const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
const [innovationToDelete, setInnovationToDelete] = useState(null);


  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleChangeTab = (event, newValue) => setSelectedTab(newValue);

  const handleEdit = (innovation) => {
    setSelectedInnovation(innovation);
    setOpenEditDialog(true);
  };

  const handleDelete = (id) => {
    setInnovationToDelete(id);
    setOpenDeleteDialog(true);
  };
  
  const confirmDelete = async () => {
    if (!innovationToDelete) return;
  
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this innovation!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          await axios.delete(`/innovations/${innovationToDelete}`);
  
          swal("Poof! Your innovation has been deleted!", {
            icon: "success",
          });
  
          setOpenDeleteDialog(false); 
         
        } catch (error) {
          console.error("Error deleting innovation:", error.response?.data?.message || error.message);
          swal("Oops!", "Something went wrong while deleting!", "error");
        }
      } else {
        swal("Your innovation is safe!");
        setOpenDeleteDialog(false);
      }
    });
  };
  

  const handleRecall = async (id) => {
    swal({
      title: "Are you sure?",
      text: "Once recalled, this innovation will be moved to the 'Recalled' section!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willRecall) => {
      if (willRecall) {
        try {
          const response = await axios.put(`/innovations/${id}/recall`);
          swal("Success!", "Innovation has been recalled.", "success");
          setOpenRecallDialog(false);
        } catch (error) {
          console.error("Error recalling innovation:", error.response?.data?.message || error.message);
          swal("Oops!", "Something went wrong while recalling!", "error");
        }
      } else {
        swal("Your innovation remains unchanged!");
        setOpenRecallDialog(false);
      }
    });
  };
  
  
  const handleUpdate = (updatedInnovation) => {
    console.log("Updated innovation:", updatedInnovation);
    setOpenEditDialog(false);
  };

  const filteredInnovations = listOfInnovations.filter((innovation) => {
    if (selectedTab === 0) return true; // All innovations
    if (selectedTab === 1) return innovation.approved; // Approved
    if (selectedTab === 2) return !innovation.approved && !innovation.recalled; // Pending
    if (selectedTab === 3) return innovation.recalled; // Recalled
    return true;
  });
  

  return (
    <Layout>
      <Box marginTop="150px" marginBottom="100px">
        <Typography
          marginTop="50px"
          variant="h6"
          gutterBottom
          style={{
            fontFamily: 'Noto Sans, sans-serif',
            fontWeight: 'bold',
            color: '#0468B1',
            letterSpacing: '1px',
            textTransform: 'uppercase',
            marginBottom: '15px',
          }}
        >
          My Innovations
        </Typography>
        <hr />

        {/* Tabs for All, Approved, Pending, and Recall */}
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="All" />
          <Tab label="Approved" />
          <Tab label="Pending" />
          <Tab label="Recall" /> {/* New Recall Tab */}
        </Tabs>

        <Grid container justifyContent="flex-end">
          <Button
            onClick={handleOpenModal}
            variant="contained"
            style={{ marginBottom: '20px', backgroundColor: '#0468B1', color: 'white' }}
          >
            <AddIcon />
          </Button>
          <Dialog
            open={openModal}
            onClose={handleCloseModal}
            maxWidth="lg"
            PaperProps={{
              style: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                maxHeight: '650px',
                minHeight: '200px',
                borderRadius: 16,
              },
            }}
          >
            <DialogTitle>Add New</DialogTitle>
            <DialogContent>
              <AddInnovation />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal} style={{ color: 'red' }}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>

        {/* Innovation Table */}
        {filteredInnovations.length === 0 ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <Typography variant="h6" style={{ marginTop: '50px', color: '#0468B1', textAlign: 'center' }}>
              No innovations found.
            </Typography>
          </Box>
        ) : (
          <Box sx={{ overflowX: 'auto', padding: '0px' }}>
            <TableContainer component={Paper} elevation={3}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredInnovations.map((innovation) => (
                    <TableRow
                      key={innovation._id}
                      hover
                      sx={{ cursor: 'pointer' }}
                      component={Link}
                      to={`/innovation/${innovation._id}`}
                    >
                      <TableCell>
                        <Typography sx={{ color: '#0468B1' }}>
                          {innovation.innovationName}
                        </Typography>
                      </TableCell>
                      <TableCell>
  <Typography variant="body1" color="textSecondary">
    <span style={{ color: innovation.recalled ? 'red' : innovation.approved ? 'green' : 'orange' }}>
      {innovation.recalled ? 'Recalled' : innovation.approved ? 'Approved' : 'Pending'}
    </span>
  </Typography>
</TableCell>

                      <TableCell>
                        {/* Action Buttons with Tooltips */}
                        <Tooltip title="Edit Innovation">
  <IconButton
    aria-label="edit"
    color="primary"
    onClick={(e) => {
      e.preventDefault();
      handleEdit(innovation);
    }}
    disabled={innovation.recalled} // Disable if recalled
  >
    <EditIcon />
  </IconButton>
</Tooltip>


                        <Tooltip title="Delete Innovation">
                          <IconButton
                            aria-label="delete"
                            color="error"
                            onClick={(e) => {
                              e.preventDefault();
                              handleDelete(innovation._id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Recall Innovation">
  <IconButton
    aria-label="recall"
    color="secondary"
    onClick={(e) => {
      e.preventDefault();
      handleRecall(innovation._id); // Directly trigger SweetAlert
    }}
    disabled={innovation.approved || innovation.recalled} // Disable if already recalled
  >
    <HistoryIcon />
  </IconButton>
</Tooltip>



                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Box>
      <Dialog
  open={openRecallDialog}
  onClose={() => setOpenRecallDialog(false)}
  maxWidth="sm"
>
  <DialogTitle>Confirm Recall</DialogTitle>
  <DialogContent>
    <Typography>
      Are you sure you want to recall this innovation? Once recalled, you will not be able to resubmit it.
    </Typography>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpenRecallDialog(false)} color="primary">
      Cancel
    </Button>
    <Button
      onClick={async () => {
        if (innovationToRecall) {
          await handleRecall(innovationToRecall._id);
        }
        setOpenRecallDialog(false);
      }}
      color="error"
      variant="contained"
    >
      Recall
    </Button>
  </DialogActions>
</Dialog>
<Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)} maxWidth="sm">
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete this innovation? This action cannot be undone.</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDeleteDialog(false)} color="primary">Cancel</Button>
        <Button onClick={confirmDelete} color="error" variant="contained">Delete</Button>
      </DialogActions>
    </Dialog>
      {/* Edit Innovation Dialog */}
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <DialogTitle>Edit Innovation</DialogTitle>
        <DialogContent>
          {selectedInnovation && (
            <EditInnovationForm
              innovation={selectedInnovation}
              open={openEditDialog}
              onClose={() => setOpenEditDialog(false)}
              onUpdate={handleUpdate}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export default MyInnovationsPage;
